import styled from 'styled-components';

import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface TitleHeadingProps {
  inline?: boolean;
}

const TitleHeading = styled.h1<TitleHeadingProps>((props) => ({
  marginTop: 0,
  marginBottom: props.inline ? 0 : 20,
  fontSize: 48,
  fontWeight: theme.weights.headingBold,
  lineHeight: '42px',
  letterSpacing: '-0.2',
  color: 'inherit',
  fontFamily: theme.fonts.heading,
  [desktop]: {
    fontSize: 120,
    lineHeight: '120px',
  },
  'p + &': {
    marginTop: '1em',
  },
}));

export default TitleHeading;
