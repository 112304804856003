import React, { useState } from 'react';

import Container from 'components/layout/Container';
import Link from 'components/Link';

import { NavColumn } from '../types';

import {
  DesktopOnly,
  Wrapper,
  Columns,
  Column,
  Underlay,
  TabsRow,
  ContentRow,
  TabsFlexRow,
} from './styles';
import { MegaNavDesktopProps, MegaNavDesktopContentProps } from './types';

function DesktopColumn({ column }: { column: NavColumn }) {
  const titleEl = column.path ? (
    <Link variant="secondary" href={column.path}>
      <strong>{column.title}</strong>
    </Link>
  ) : (
    <strong>{column.title}</strong>
  );

  return (
    <Column>
      {titleEl}
      <ul>
        {column.links?.map((link) => {
          if (!link.path || !link.title) {
            return null;
          }

          return (
            <li key={link._key}>
              <Link href={link.path} variant="secondary">
                {link.title}
              </Link>
            </li>
          );
        })}
        {column.path ? (
          <li>
            <Link href={column.path} variant="secondary">
              View All
            </Link>
          </li>
        ) : null}
      </ul>
    </Column>
  );
}

function MegaNavDesktopContent({ section }: MegaNavDesktopContentProps) {
  const [selectedTab, setSelectedTab] = useState(section.subItems?.[0]);

  return (
    <>
      {section.subItems && section.subItems.length > 1 ? (
        <TabsRow>
          <Container>
            <TabsFlexRow>
              {section.subItems?.map((tab) => (
                <Link
                  variant="secondary"
                  key={tab._key}
                  href={tab.path || '#'}
                  active={selectedTab === tab}
                  onMouseOver={() => setSelectedTab(tab)}
                >
                  {tab.title}
                </Link>
              ))}
            </TabsFlexRow>
          </Container>
        </TabsRow>
      ) : null}
      {selectedTab?.subItems ? (
        <ContentRow>
          <Container noGutter>
            <Columns>
              {selectedTab.subItems.map((column) => (
                <DesktopColumn column={column} key={column._key} />
              ))}
            </Columns>
          </Container>
        </ContentRow>
      ) : null}
    </>
  );
}

export default function MegaNavDesktop({
  section,
  isOpen,
  transitionDuration,
  onClose,
}: MegaNavDesktopProps) {
  return (
    <DesktopOnly>
      {section.subItems?.length ? (
        <Wrapper isOpen={isOpen} transitionDuration={transitionDuration}>
          <MegaNavDesktopContent key={section._key} section={section} />
        </Wrapper>
      ) : null}
      <Underlay
        isOpen={isOpen}
        transitionDuration={transitionDuration}
        onMouseOver={onClose}
      />
    </DesktopOnly>
  );
}
