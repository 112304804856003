import React from 'react';

import {
  PressBannerSection,
  FlexRow,
  PressBannerItem,
  PressBannerContainer,
  LogoImg,
} from './styles';
import { PressBannerProps } from './types';

function PressBanner({ images }: PressBannerProps) {
  return (
    <PressBannerSection>
      <PressBannerContainer noPadding>
        <FlexRow>
          {images
            ? images.map(({ _key, alt, cta, asset }) => (
                <PressBannerItem key={_key}>
                  <a
                    href={cta?.url ? cta?.url : ''}
                    target={cta?.url ? 'blank' : ''}
                  >
                    <LogoImg alt={alt} src={asset?.url} />
                  </a>
                </PressBannerItem>
              ))
            : null}
        </FlexRow>
      </PressBannerContainer>
    </PressBannerSection>
  );
}

export default PressBanner;
