import React, { useState, useRef, useEffect } from 'react';

import { IconButton } from 'components/global/Header/styles';
import { Search as SearchIcon, Close } from 'components/icons';
import Logo from 'components/Logo';

import {
  Input,
  SearchOverlay,
  SearchForm,
  SearchIconLabel,
  SearchLogoWrapper,
  SearchHeaderInner,
  SearchHeaderWrapper,
} from './styles';

const Search = () => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef && inputRef.current && inputRef.current.focus();
      }, 10);
    }
  }, [isOpen]);

  return (
    <>
      <SearchIconLabel
        as="label"
        htmlFor="search-input"
        data-testid="search"
        onClick={() => setIsOpen(!isOpen)}
      >
        <SearchIcon />
        Search
      </SearchIconLabel>

      <SearchOverlay active={isOpen}>
        <SearchHeaderWrapper>
          <SearchHeaderInner>
            <SearchLogoWrapper>
              <Logo />
            </SearchLogoWrapper>
            <SearchForm action="/search" method="get">
              <SearchIcon />
              <Input
                name="q"
                type="search"
                id="search-input"
                placeholder="Search"
                onFocus={() => setIsOpen(true)}
                ref={inputRef}
              />
            </SearchForm>
            <IconButton type="button" onClick={() => setIsOpen(false)}>
              <Close width={20} height={20} />
            </IconButton>
          </SearchHeaderInner>
        </SearchHeaderWrapper>
      </SearchOverlay>
    </>
  );
};

export default Search;
