import jsCookie from 'js-cookie';
import { useEffect, useState } from 'react';

import { useCookieAccepted } from 'components/global/CookieBanner/utils';

const SEEN_COOKIE_NAME = 'sprtl.email-popup-seen';
const SEEN_COOKIE_VALUE = 'seen';
const SEEN_COOKIE_MAXAGE = 30; // one month

const ACCEPTED_COOKIE_NAME = 'sprtl.email-popup-accepted';
const ACCEPTED_COOKIE_VALUE = 'accepted';
const ACCEPTED_COOKIE_MAXAGE = 365 * 10; // 10 years

export const popupSeen = () =>
  jsCookie.get(SEEN_COOKIE_NAME) === SEEN_COOKIE_VALUE;

export const popupAccepted = () =>
  jsCookie.get(ACCEPTED_COOKIE_NAME) === ACCEPTED_COOKIE_VALUE;

export const setPopupSeen = () =>
  jsCookie.set(SEEN_COOKIE_NAME, SEEN_COOKIE_VALUE, {
    path: '/',
    expires: SEEN_COOKIE_MAXAGE,
  });

export const setPopupAccepted = () =>
  jsCookie.set(ACCEPTED_COOKIE_NAME, ACCEPTED_COOKIE_VALUE, {
    path: '/',
    expires: ACCEPTED_COOKIE_MAXAGE,
  });

const calculate = () => !popupSeen() && !popupAccepted();

export const useEligibleToShow = () => {
  const hasCookieAccepted = useCookieAccepted();

  const [internalState, setInternalState] = useState(false);

  useEffect(() => {
    setInternalState(hasCookieAccepted ? calculate() : false);
  }, [hasCookieAccepted]);

  return internalState;
};
