import { pick } from 'lodash';
import React, { Fragment } from 'react';

import { AddressDataFragment } from 'generated/api/graphql';

import { AddressFields } from './checkout/types';

const defaultAddressAttrKeys: Array<keyof AddressFields> = [
  'firstName',
  'lastName',
  'streetName',
  'additionalStreetInfo',
  'city',
  'postalCode',
  'phone',
  'email',
  'country',
];

export function formatAddressLine(address: AddressDataFragment) {
  const nodes = [
    address.streetName,
    address.additionalStreetInfo,
    address.city,
    address.postalCode,
  ]
    .filter((val) => val)
    .map((val) => (
      <span key={val} style={{ whiteSpace: 'nowrap' }}>
        {val}
      </span>
    ));

  return (
    <>
      {nodes.map((node, index) => (
        <Fragment key={index}>
          {node}
          {index + 1 === nodes.length ? '' : ', '}
        </Fragment>
      ))}
    </>
  );
}

export const defaultAddressAttrs: AddressFields = {
  firstName: '',
  lastName: '',
  streetName: '',
  additionalStreetInfo: '',
  city: '',
  postalCode: '',
  phone: '',
  email: '',
  country: 'GB',
};

export const getAddressFromObject = (address: any) =>
  pick(address, defaultAddressAttrKeys);
