import React, { useState } from 'react';

import { Close } from 'components/icons';
import Link from 'components/Link';
import { isClientSide } from 'utils/helpers';

import {
  Wrapper,
  Title,
  Text,
  StyledContainer,
  CtaContainer,
  CloseButton,
} from './styles';
import { cookieAccepted, setCookieAccepted } from './utils';

export default function CookieBanner() {
  const hasCookieBeenAccepted = cookieAccepted();
  const [showBanner, setShowBanner] = useState(!hasCookieBeenAccepted);

  const acceptCookie = () => {
    setCookieAccepted();

    setShowBanner(false);
  };

  return showBanner && isClientSide() ? (
    <Wrapper>
      <StyledContainer>
        <div>
          <Title>Cookie Information</Title>
          <Text>
            This site uses cookies. Read more in our{' '}
            <Link href="/about/privacy-policy">Privacy Policy</Link>
          </Text>
        </div>
        <CtaContainer>
          <CloseButton onClick={acceptCookie}>
            <Close />
          </CloseButton>
        </CtaContainer>
      </StyledContainer>
    </Wrapper>
  ) : null;
}
