import styled from 'styled-components';

import theme from 'utils/theme';

export const InputWrapper = styled.div`
  width: 100%;
`;

export const SubmitStateText = styled.div`
  font-size: 14px;
  margin-top: 10px;
  line-height: 1.5;

  a {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const SuccessText = styled.span`
  color: ${theme.colors.pink};
`;
