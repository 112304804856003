import styled from 'styled-components';

import theme from 'utils/theme';

export const Wrapper = styled.div`
  position: relative;
`;

export const Badge = styled.div`
  background-color: ${theme.colors.lightGreen};
  color: ${theme.colors.black};
  font-size: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px;
  border-radius: 10px;
  position: absolute;
  top: 3px;
  right: -8px;
  min-width: 15px;
  height: 15px;
  text-align: center;
`;
