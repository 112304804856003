import styled from 'styled-components';

import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface Heading1Props {
  inline?: boolean;
}

const Heading2 = styled.h2<Heading1Props>((props) => ({
  marginTop: 0,
  marginBottom: props.inline ? 0 : 20,
  fontSize: 40,
  fontWeight: theme.weights.headingBold,
  lineHeight: '46px',
  letterSpacing: '-0.2',
  color: 'inherit',
  fontFamily: theme.fonts.heading,
  [desktop]: {
    fontSize: 64,
    lineHeight: '70px',
  },
  'p + &, h1 + &': {
    marginTop: '1em',
  },
}));

export default Heading2;
