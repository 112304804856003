import styled from 'styled-components';

import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface Heading5Props {
  inline?: boolean;
}

const Heading5 = styled.h5<Heading5Props>((props) => ({
  marginTop: 0,
  marginBottom: props.inline ? 0 : 20,
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 'bold',
  color: 'inherit',
  fontFamily: theme.fonts.heading,
  ...(props.inline
    ? {}
    : {
        'p + &, h1 + &, h2 + &': {
          marginTop: '1.5em',
        },
      }),
  [desktop]: {
    fontSize: 24,
    lineHeight: '30px',
  },
}));

export default Heading5;
