import styled, { CSSObject } from 'styled-components';

import theme from 'utils/theme';

interface InputElProps {
  variant: 'primary' | 'range' | 'secondary';
  bg: 'white' | 'lightestGrey';
  blurPlaceholder?: string;
}

export const InputElWrapper = styled.div`
  position: relative;
`;

export const BlurPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${theme.colors.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const variantStyles = (props: InputElProps): Record<string, CSSObject> => ({
  primary: {},
  range: {
    width: 100,
    height: 34,
    padding: 4,
    textAlign: 'center',
    borderWidth: 2,
    backgroundColor: 'transparent',
    borderColor: theme.colors.grey,
    color: props.blurPlaceholder ? 'transparent' : theme.colors.grey,

    '&:focus': {
      borderColor: theme.colors.black,
      color: theme.colors.black,
      outline: 'none',

      [`+ ${BlurPlaceholder}`]: {
        display: 'none',
      },
    },
  },
  secondary: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: 'inherit',
    padding: '0px 0px 8px 0px',
    '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus': {
      '-webkit-text-fill-color': theme.colors.white,
      '-webkit-box-shadow': `0 0 0px 1000px ${theme.colors.offBlack} inset`,
      transition: 'background-color 5000s ease-in-out 0s',
    },
    '&:focus': {
      backgroundColor: 'transparent',
      outline: 'none',
    },
  },
});

export const InputEl = styled.input<InputElProps>((props) => ({
  width: '100%',
  height: theme.sizes.input.height,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.colors.lightGrey,
  backgroundColor: theme.colors[props.bg],
  padding: '10px 15px',
  borderRadius: 40,
  fontSize: theme.sizes.fonts.input,
  '&:focus': {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.purple,
    outline: 'none',
    boxShadow: `0 0px 0px 1px ${theme.colors.purple}`,
  },
  '&::placeholder': {
    color: theme.colors.midGrey,
    opacity: 1,
  },
  '::-webkit-outer-spin-button,::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  ...variantStyles(props)[props.variant],
}));
