import styled from 'styled-components';

import { tablet } from 'utils/media';
import theme from 'utils/theme';

export interface SectionProps extends React.ComponentPropsWithRef<'section'> {
  bg?: 'light' | 'dark' | 'normal' | 'irisPurple' | null;
  padding?: 'none' | 'small' | 'medium';
  fullBleed?: boolean;
}

const bgColors = {
  irisPurple: theme.colors.irisPurple,
  light: theme.colors.white,
  normal: theme.colors.offWhite,
  dark: theme.colors.darkBg,
};

const paddingSizes = {
  none: {
    mobile: '0',
    tablet: '0',
  },
  small: {
    mobile: '10px 0',
    tablet: '30px 0',
  },
  medium: {
    mobile: '40px 0',
    tablet: '60px 0',
  },
};

const BaseSection = styled.section<SectionProps>`
  background-color: ${({ bg }) => (bg ? bgColors[bg] : undefined)};
  padding: ${({ padding = 'medium' }) =>
    padding ? paddingSizes[padding].mobile : '0'};

  ${tablet} {
    padding: ${({ padding = 'medium' }) =>
      padding ? paddingSizes[padding].tablet : '0'};
  }
`;

const FullBleedSection = styled(BaseSection)`
  & + & {
    margin-top: 30px;
  }
`;

const LightSection = styled(BaseSection)`
  & + & {
    padding-top: 0;
  }
`;

const Section = (props: SectionProps) => {
  let Comp = BaseSection;
  if (props.fullBleed) {
    Comp = FullBleedSection;
  } else if (props.bg === 'light') {
    Comp = LightSection;
  }

  return <Comp {...props} />;
};

export default Section;
