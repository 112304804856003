import styled from 'styled-components';

import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface Heading6Props {
  inline?: boolean;
}

const Heading6 = styled.h6<Heading6Props>((props) => ({
  marginTop: 0,
  marginBottom: props.inline ? 0 : 20,
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 'bold',
  color: 'inherit',
  letterSpacing: 1,
  fontFamily: theme.fonts.heading,
  'p + &, h1 + &, h2 + &': {
    marginTop: '1.5em',
  },
  [desktop]: {
    fontSize: 24,
    lineHeight: '30px',
  },
}));

export default Heading6;
