import styled from 'styled-components';

import { IconButton } from 'components/global/Header/styles';
import { largeDesktop, tablet } from 'utils/media';

export const ProfileIconButton = styled(IconButton)({
  svg: {
    width: 26,
    height: 26,
  },

  [largeDesktop]: {
    svg: {
      width: 35,
      height: 35,
    },
  },
});

export const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  overflow: hidden;

  ${tablet} {
    width: 40px;
    height: 40px;
  }
`;
