import React from 'react';

import theme from 'utils/theme';

interface LogoIconProps {
  width?: number;
  height?: number;
  className?: string;
}

export default function LogoIcon(props: LogoIconProps) {
  return (
    <svg
      width={33}
      height={30}
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.28 16.58l.001-.004c.465-1.311.718-2.723.718-4.195V0h-.002-.002a15.29 15.29 0 00-10.87 4.528 15.439 15.439 0 00-4.502 10.83v1.22H15.38v-1.119c0-4.27-1.721-8.132-4.506-10.93A15.286 15.286 0 00.002 0H0v12.38c0 1.473.253 2.885.72 4.196l.001.004c1.712 4.815 6.286 8.264 11.66 8.277V30h8.25v-5.144c5.373-.016 9.937-3.464 11.649-8.276z"
        fill={theme.colors.coleusGreen}
      />
    </svg>
  );
}
