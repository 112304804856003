import styled from 'styled-components';

import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface Heading1Props {
  inline?: boolean;
}

const Heading1 = styled.h1<Heading1Props>((props) => ({
  marginTop: 0,
  marginBottom: props.inline ? 0 : 20,
  fontSize: 48,
  fontWeight: theme.weights.headingBold,
  lineHeight: '54px',
  letterSpacing: '-0.2',
  color: 'inherit',
  fontFamily: theme.fonts.heading,
  [desktop]: {
    fontSize: 80,
    lineHeight: '86px',
  },
  'p + &': {
    marginTop: '1em',
  },
}));

export default Heading1;
