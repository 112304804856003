import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useEffect } from 'react';

import Button from 'components/Button';
import Input from 'components/forms/Input';
import { useCookieAccepted } from 'components/global/CookieBanner/utils';
import { Close, LogoIcon } from 'components/icons';
import SiteLink from 'components/Link';
import ModalNotification from 'components/ModalNotification';
import { Heading4 } from 'components/text';

import {
  // VariantDetails,
  Wrapper,
  CloseButton,
  StyledContainer,
  ButtonWrapper,
  BackgroundWrapper,
  FormWrapper,
} from './styles';
import { useEligibleToShow, setPopupAccepted, setPopupSeen } from './utils';

const EmailPopup = () => {
  const [submitState, setSubmitState] = useState<null | 'success'>(null);

  const eligibleToShow = useEligibleToShow();

  const [isModalOpen, setIsModalOpen] = useState(eligibleToShow);
  const hasCookieAccepted = useCookieAccepted();

  useEffect(() => {
    if (eligibleToShow) {
      window.setTimeout(() => {
        setIsModalOpen(true);
      }, 2000);
    }
  }, [eligibleToShow]);

  const [formShown, setFormShown] = useState(false);

  const heading = (
    <>
      <Heading4>Join our Newsletter</Heading4>
      <p>
        Sign up to get all the latest gardening tips{' '}
        <span style={{ whiteSpace: 'nowrap' }}>and tricks.</span>
      </p>
    </>
  );

  useEffect(() => {
    if (isModalOpen) {
      setPopupSeen();
    }
  }, [isModalOpen]);

  let body;

  if (submitState === 'success') {
    body = (
      <>
        <Heading4>Thanks</Heading4>
        <p>We’ve got it, we’ll be in touch soon.</p>
        <ButtonWrapper>
          <Button
            type="button"
            variant="white"
            onClick={() => setIsModalOpen(false)}
          >
            Continue Shopping
          </Button>
        </ButtonWrapper>
      </>
    );
  } else if (formShown) {
    body = (
      <>
        {heading}
        <FormWrapper>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={async (values, actions) => {
              const cleanEmail = values.email.trim();

              setSubmitState(null);

              if (cleanEmail) {
                axios({
                  method: 'post',
                  data: {
                    email: cleanEmail,
                  },
                  url: '/api/iterable/subscribe',
                }).then(() => setPopupAccepted());

                actions.setFieldValue('email', '');
                setSubmitState('success');
              }
            }}
          >
            <Form>
              <Input
                type="email"
                name="email"
                autoComplete="email"
                placeholder="Email address"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
              <p>
                By subscribing you agree to our{' '}
                <SiteLink
                  href="/about/terms-and-conditions"
                  target="_blank"
                  underline
                >
                  Terms & Conditions
                </SiteLink>{' '}
                and{' '}
                <SiteLink
                  href="/about/privacy-policy"
                  target="_blank"
                  underline
                >
                  Privacy & Cookies Policy
                </SiteLink>
                .
              </p>
              <ButtonWrapper>
                <Button type="submit" variant="white">
                  Sign Me Up
                </Button>
              </ButtonWrapper>
            </Form>
          </Formik>
        </FormWrapper>
      </>
    );
  } else {
    body = (
      <>
        {heading}
        <ButtonWrapper>
          <Button
            type="button"
            variant="white"
            onClick={() => setFormShown(true)}
          >
            Sign Me Up
          </Button>
        </ButtonWrapper>
      </>
    );
  }

  return (
    <ModalNotification
      isBespokeModal
      isOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
    >
      <StyledContainer>
        <Wrapper
          cookieAccepted={hasCookieAccepted}
          isOpen={isModalOpen}
          data-testid="emailPopup"
        >
          <BackgroundWrapper>
            <LogoIcon />
            <LogoIcon />
            <LogoIcon />
          </BackgroundWrapper>
          <CloseButton type="button" onClick={() => setIsModalOpen(false)}>
            <Close height={16} width={16} />
          </CloseButton>
          {body}
        </Wrapper>
      </StyledContainer>
    </ModalNotification>
  );
};

export default EmailPopup;
