import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import useDelayedRender from 'use-delayed-render';

import { Hamburger } from 'components/icons';
import SiteLink from 'components/Link';
import Logo from 'components/Logo';
import theme from 'utils/theme';

import Basket from './components/Basket';
import MegaNavDesktop from './components/MegaNav/Desktop';
import MegaNavMobile from './components/MegaNav/Mobile';
import { NavRoot, NavSection } from './components/MegaNav/types';
import Profile from './components/Profile';
import Search from './components/Search';
import {
  LogoLink,
  StyledContainer,
  Background,
  HamburgerTempWrapper,
  IconsWrapper,
  HeaderNavLinks,
  RelativeWrapper,
  LogoWrapper,
} from './styles';

interface HeaderProps {
  nav: NavRoot;
}

export default function Header({ nav }: HeaderProps) {
  const router = useRouter();
  /*
   * On Mobile the state is true/false depending on whether the user has clicked the Menu hamburger icon.
   * On Desktop, the state refers to the indidivual NavSection the user has hovered over.
   */
  const [navState, setNavState] = useState<NavSection | boolean>(false);
  const [persistentNavState, setPersistentNavState] = useState<NavSection>();
  const hoverRef = useRef<NavSection | null>(null);

  const isActive = !!navState;

  /**
   * Delay the unmount of nav items, to allow a CSS transition exit. It's transition-group but tiny
   */
  const transitionDuration = theme.transitionSpeeds.fast * 1000;
  const { mounted: isMobileNavMounted, rendered: isMobileNavRendered } =
    useDelayedRender(isActive, {
      exitDelay: transitionDuration,
    });

  useEffect(() => {
    if (!navState) {
      (document.activeElement as HTMLElement)?.blur();
    }

    if (navState && navState !== true) {
      setPersistentNavState(navState);
    }
  }, [navState, isMobileNavMounted]);

  useEffect(() => {
    const handleRouteEnd = () => {
      setNavState(false);
      hoverRef.current = null;
    };

    router.events.on('routeChangeComplete', handleRouteEnd);

    return () => {
      router.events.off('routeChangeComplete', handleRouteEnd);
    };
  }, [router.events]);

  const setHoveredSection = useCallback(
    (state = null) => {
      hoverRef.current = state;
    },
    [hoverRef],
  );

  /**
   * Add a slight delay to the nav link hover to make it less obnoxious
   */
  const onHover = useCallback(
    (section: NavSection) => {
      setHoveredSection(section);

      if (isMobileNavMounted) {
        setNavState(section);
        return;
      }

      setTimeout(() => {
        if (section === hoverRef.current) {
          setNavState(section);
        }
      }, 75);
    },
    [hoverRef, isMobileNavMounted, setHoveredSection],
  );

  return (
    <RelativeWrapper>
      <Background data-testid="header" isActive={isActive}>
        <StyledContainer noGutter>
          <LogoWrapper>
            <Link href="/" passHref>
              <LogoLink isActive={isActive}>
                <Logo />
                Sproutl
              </LogoLink>
            </Link>
          </LogoWrapper>
          <HeaderNavLinks>
            {nav.map((section, index) => (
              <li key={index}>
                <SiteLink
                  variant="secondary"
                  href={section.path || '#'}
                  onPointerOver={() => onHover(section)}
                  onPointerOut={setHoveredSection}
                  onTouchEnd={(e) => {
                    if (navState !== section) {
                      setNavState(section);
                      e.preventDefault();
                    }
                  }}
                  active={navState && navState === section}
                >
                  {section.title}
                </SiteLink>
              </li>
            ))}
          </HeaderNavLinks>
          <IconsWrapper>
            <Search />
            <Basket />
            <Profile />
          </IconsWrapper>
          <HamburgerTempWrapper
            onClick={() => {
              setNavState(true);
            }}
            aria-label="Menu"
          >
            <Hamburger />
          </HamburgerTempWrapper>
        </StyledContainer>
      </Background>

      {isMobileNavMounted && typeof navState === 'boolean' && (
        <MegaNavMobile
          nav={nav}
          isOpen={isMobileNavRendered}
          transitionDuration={transitionDuration}
          onClose={() => setNavState(false)}
        />
      )}
      {isMobileNavMounted && persistentNavState && (
        <MegaNavDesktop
          section={persistentNavState}
          isOpen={isMobileNavRendered}
          transitionDuration={transitionDuration}
          onClose={() => {
            setNavState(false);
            setHoveredSection();
          }}
        />
      )}
    </RelativeWrapper>
  );
}
