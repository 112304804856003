import React, { useState, useEffect } from 'react';

import { BannerTopFadeOut, HeaderAlertLink, Wrapper } from './styles';
import { IHeaderAlertProps } from './types';

function HeaderAlert({ alerts }: IHeaderAlertProps) {
  const [alertIndex, setAlertIndex] = useState(0);

  useEffect(() => {
    if (!alerts || alerts.length == 1) {
      return;
    }

    const interval = setInterval(() => {
      setAlertIndex((alertIndex) => {
        let nextIndex = alertIndex + 1;

        alertIndex = nextIndex >= alerts.length ? 0 : nextIndex;
        return alertIndex;
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [alerts, setAlertIndex]);

  if (!alerts?.length) {
    return null;
  }

  return (
    <Wrapper>
      <BannerTopFadeOut>
        {alerts.map((alert, index) => (
          <HeaderAlertLink
            href={alert.alertName ? alert.alertName.url : ''}
            target={alert.alertName ? 'blank' : ''}
            key={alert?.alertName?.title}
            active={index === alertIndex}
          >
            {alert?.alertName?.title}
          </HeaderAlertLink>
        ))}
      </BannerTopFadeOut>
    </Wrapper>
  );
}

export default HeaderAlert;
