import React, { useEffect, useState } from 'react';

import { Close, Help, LeftArrow, RightArrow } from 'components/icons';
import Image from 'components/Image';
import { Heading4 } from 'components/text';
import { setMobileDialogOpen } from 'utils/ui';

import { NavLink, NavScreen } from '../types';

import {
  MobileContent,
  MobileInternalButton,
  MobileInternalNavigation,
  MobileNavScreen,
  MobileNavScreenRows,
  NavRowButton,
  NavRowLink,
  ScreenHero,
  ScreenHeroHeading,
  Wrapper,
  ScreenFooterLinks,
  ScreenView,
  ScreenHeroUnderlay,
  MobileInternalButtonAnimation,
} from './styles';
import {
  MegaNavMobileProps,
  MobileNavRowProps,
  MobileNavScreenContentProps,
} from './types';

function MobileNavRow({ item, onNavigateToScreen }: MobileNavRowProps) {
  const isButton =
    ('subItems' in item && item.subItems?.length) || 'links' in item;

  const children = (
    <>
      <span>{item?.title}</span>
      {isButton ? <RightArrow /> : null}
    </>
  );

  return isButton ? (
    <NavRowButton
      onClick={() => {
        onNavigateToScreen(item as NavScreen);
      }}
    >
      {children}
    </NavRowButton>
  ) : (
    <NavRowLink variant="secondary" href={(item as NavLink).path || '#'}>
      {children}
    </NavRowLink>
  );
}

function MobileNavScreenContent({
  item,
  onNavigate,
}: MobileNavScreenContentProps) {
  let rows;
  if (Array.isArray(item)) {
    rows = item;
  } else {
    rows =
      'links' in item ? item.links : 'subItems' in item ? item.subItems : null;
  }

  return (
    <ScreenView>
      {'title' in item ? (
        <ScreenHero aspectRatio={0.3866666}>
          {item.heroImage ? (
            <>
              <Image
                src={item.heroImage}
                aspectRatio={0.3866666}
                alt=""
                sizes="100vw"
              />
              <ScreenHeroUnderlay />
            </>
          ) : null}
          <ScreenHeroHeading hasImage={!!item.heroImage}>
            <Heading4 inline>{item.title}</Heading4>
          </ScreenHeroHeading>
        </ScreenHero>
      ) : null}
      <MobileNavScreenRows>
        {(item as NavScreen)?.path ? (
          <NavRowLink
            variant="secondary"
            href={(item as NavScreen).path || '#'}
          >
            View All
          </NavRowLink>
        ) : null}
        {rows?.map((subItem) => (
          <MobileNavRow
            item={subItem}
            key={subItem._key}
            onNavigateToScreen={onNavigate}
          />
        ))}
      </MobileNavScreenRows>
      <ScreenFooterLinks>
        <NavRowLink variant="secondary" href="/about/contact-us">
          <span>
            <Help />
            Need some help?
          </span>
        </NavRowLink>
      </ScreenFooterLinks>
    </ScreenView>
  );
}

export default function MegaNavMobile({
  nav,
  isOpen,
  transitionDuration,
  onClose,
}: MegaNavMobileProps) {
  const [currentScreens, setCurrentScreens] = useState<NavScreen[]>([]);
  const [currentRenderedScreens, setCurrentRenderedScreens] = useState<
    NavScreen[]
  >([]);

  useEffect(() => {
    setMobileDialogOpen(true);

    return () => {
      setMobileDialogOpen(false);
    };
  }, []);

  const screensPush = (screen: NavScreen) => {
    const screenToPush =
      'subItems' in screen && screen.subItems && screen.subItems.length === 1
        ? screen.subItems[0]
        : screen;

    setCurrentScreens([...currentScreens, screenToPush]);
    setCurrentRenderedScreens([...currentScreens, screenToPush]);
  };

  const screensBack = () => {
    const screenWrapperEl = document.getElementById('mobileNavScreens');
    if (screenWrapperEl) {
      const screenNodes = screenWrapperEl.childNodes;
      const previousScreenEl = screenNodes.item(screenNodes.length - 2);
      if (previousScreenEl) {
        (previousScreenEl as HTMLDivElement)?.focus();
      }
    }

    const newScreens = [...currentScreens];
    newScreens.pop();
    setCurrentScreens(newScreens);

    setTimeout(() => {
      setCurrentRenderedScreens(newScreens);
    }, transitionDuration);
  };

  return (
    <Wrapper transitionDuration={transitionDuration} isOpen={isOpen}>
      <MobileInternalNavigation>
        <div>
          <MobileInternalButtonAnimation
            active={!!currentRenderedScreens.length}
          >
            <MobileInternalButton type="button" onClick={screensBack}>
              <LeftArrow />
            </MobileInternalButton>
          </MobileInternalButtonAnimation>
        </div>
        <MobileInternalButton type="button" onClick={onClose}>
          <Close />
        </MobileInternalButton>
      </MobileInternalNavigation>
      <MobileContent id="mobileNavScreens">
        <MobileNavScreen
          tabIndex={0}
          isOpen={true}
          transitionDuration={transitionDuration}
        >
          <MobileNavScreenContent item={nav} onNavigate={screensPush} />
        </MobileNavScreen>

        {currentRenderedScreens.map((screen) => (
          <MobileNavScreen
            tabIndex={0}
            key={`subScreen_${screen._key}`}
            isOpen={currentScreens.includes(screen)}
            transitionDuration={transitionDuration}
          >
            <MobileNavScreenContent item={screen} onNavigate={screensPush} />
          </MobileNavScreen>
        ))}
      </MobileContent>
    </Wrapper>
  );
}
