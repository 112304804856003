import { buildUrl } from 'cloudinary-build-url';
import NextImage, { ImageLoaderProps } from 'next/image';
import React from 'react';
import styled from 'styled-components';

import { urlFor } from 'utils/sanity';

import { ImageProps, BreakpointWrapperProps } from './types';

const cleanImageRegex =
  /(https:\/\/res\.cloudinary\.com\/sproutl\/images\/f_auto,q_auto,dpr_auto,w_1000,h_1000,c_limit\/|#.+)/g;
const mediaQueryRegex = /@media/gm;

const BreakpointWrapper = styled.div<BreakpointWrapperProps>((props) => ({
  display: 'none',
  [props.media]: {
    display: 'block',
  },
}));

function cloudinaryLoader({ src, width }: ImageLoaderProps): string {
  try {
    // PDP format has hardcoded transforms for CS Cart compatibility. TODO update once switchover is complete.
    let path = decodeURI(src).replace(cleanImageRegex, '');

    const newSrc = buildUrl(path, {
      cloud: {
        cloudName: 'sproutl',
      },
      transformations: {
        quality: 90,
        resize: {
          type: 'limit',
          width,
          aspectRatio: '1:1',
        },
      },
    }).replace('image/upload', 'images');

    return `${newSrc}.jpg`;
  } catch {
    // Temp fix to prevent malformed URI crash
    return src;
  }
}

export default function Image({
  src,
  alt,
  sizes,
  loader,
  objectFit,
  aspectRatio,
}: ImageProps) {
  // Defeault objectFit depending on the loader
  if (!objectFit) {
    objectFit = loader === 'cloudinary' ? 'contain' : 'cover';
  }

  const parsedSizes = sizes ? sizes.replace(mediaQueryRegex, '') : undefined;

  const baseImgBuilder = urlFor(src)
    .width(100)
    .height(100)
    .auto('format')
    .url();

  const sanityLoader =
    (loaderAspectRatio: number) =>
    ({ width, quality = 75 }: ImageLoaderProps) =>
      urlFor(src)
        .width(width)
        .height(Math.round(width * loaderAspectRatio))
        .auto('format')
        .fit('max')
        .quality(quality)
        .url() || '';

  if (!baseImgBuilder && loader !== 'cloudinary') {
    return null;
  }

  if (typeof aspectRatio === 'object') {
    return (
      <>
        {Object.entries(aspectRatio).map(([media, breakpointAspectRatio]) => (
          <BreakpointWrapper key={media} media={media}>
            <NextImage
              layout="fill"
              loader={
                loader === 'cloudinary'
                  ? cloudinaryLoader
                  : sanityLoader(breakpointAspectRatio)
              }
              objectFit={objectFit}
              src={
                loader === 'cloudinary' ? src.secure_url : baseImgBuilder || ''
              }
              key={media}
              sizes={parsedSizes}
              alt={alt}
            />
          </BreakpointWrapper>
        ))}
      </>
    );
  }

  return (
    <NextImage
      layout="fill"
      loader={
        loader === 'cloudinary' ? cloudinaryLoader : sanityLoader(aspectRatio)
      }
      objectFit={objectFit}
      src={loader === 'cloudinary' ? src.secure_url : baseImgBuilder || ''}
      sizes={parsedSizes}
      alt={alt}
    />
  );
}
