import { useField } from 'formik';
import React, { InputHTMLAttributes, Ref } from 'react';

import { BlurPlaceholder, InputEl, InputElWrapper } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: 'primary' | 'range' | 'secondary';
  name: string;
  bg?: 'white' | 'lightestGrey';
  blurPlaceholder?: string;
}

const InputInnerEl = (
  { variant = 'primary', bg = 'white', ...props }: InputProps,
  ref: Ref<HTMLInputElement | undefined>,
) => {
  const [field, meta, helpers] = useField(props);

  const { value } = meta;
  const { setValue } = helpers;

  const placeholder =
    props.placeholder && props.required
      ? `${props.placeholder}*`
      : props.placeholder;

  const inputElComponent = (
    <InputEl
      {...field}
      onBlur={(e) => {
        e.currentTarget.value = e.currentTarget.value.trim();
        field.onBlur(e);
        setValue(e.currentTarget.value);
      }}
      {...props}
      variant={variant}
      bg={bg}
      ref={ref as any}
      placeholder={placeholder}
      value={value || ''}
    />
  );

  return (
    <>
      {props.blurPlaceholder ? (
        <InputElWrapper>
          {inputElComponent}
          {props.blurPlaceholder ? (
            <BlurPlaceholder>{props.blurPlaceholder}</BlurPlaceholder>
          ) : null}
        </InputElWrapper>
      ) : (
        inputElComponent
      )}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export const Input = React.forwardRef<HTMLInputElement | undefined, InputProps>(
  InputInnerEl,
);

export default Input;
